var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidebar-component",
    _vm._g(
      _vm._b(
        {
          attrs: { title: "Map Insurance" },
          on: { save: _vm.submitInsuranceMaps },
        },
        "sidebar-component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { staticClass: "w-auto p-1" },
        [
          _c("b-row", [_c("b-col", [_c("h4", [_vm._v("Map Insurance")])])], 1),
          _c(
            "b-row",
            { staticClass: "my-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-list-group",
                    { staticStyle: { "border-radius": "6px 6px 0px 0px" } },
                    [
                      _c(
                        "b-list-group-item",
                        { staticStyle: { "background-color": "#F3F2F7" } },
                        [
                          _vm._v(
                            "\n            Insurance company's to Map\n          "
                          ),
                        ]
                      ),
                      _vm._l(_vm.insuranceIds, function (insurance) {
                        return _c(
                          "b-list-group-item",
                          { key: insurance.id },
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", [
                                  _c("b", [_vm._v(_vm._s(insurance.name))]),
                                ]),
                                _c(
                                  "b-col",
                                  { staticClass: "mr-1", attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.2000",
                                            value: "rgba(255, 69, 0, 0.5)",
                                            expression:
                                              "'rgba(255, 69, 0, 0.5)'",
                                            modifiers: { 2000: true },
                                          },
                                        ],
                                        attrs: {
                                          variant: "transparent",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.insuranceIds.splice(
                                              _vm.insuranceIds.indexOf(
                                                insurance
                                              ),
                                              1
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon-x-circle", {
                                          attrs: {
                                            scale: "1.5",
                                            variant: "danger",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Map to: (Primary)" } },
                    [
                      _c("infinit-select", {
                        attrs: {
                          repo: _vm.insuranceRepo,
                          filter: { onlyReviewed: true },
                          label: "name",
                        },
                        model: {
                          value: _vm.baseInsuranceId,
                          callback: function ($$v) {
                            _vm.baseInsuranceId = $$v
                          },
                          expression: "baseInsuranceId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }